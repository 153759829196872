/* eslint-disable */
<template>
  <v-app style="background: white">
    <section class="mt-10" v-if="course">
      <v-container
        fluid
        style="
          background-image: url(https://picsum.photos/1920/1080?random);
          min-height: 300px;
        "
      >
        <v-row justify="center" class="mt-16">
          <h1 class="text-lg-h1 mt-16" style="color: white; font-weight: bolder; font-size:6rem">
            sdfasdfasdfasdf
          </h1>
        </v-row>
      </v-container>
      <v-container fluid style="background-color: #ffc107" class="py-0">
        <v-container class="py-0">
          <v-tabs color="red" background-color="transparent">
            <v-tab>Description</v-tab>
            <!-- <v-tab>ddd</v-tab>
            <v-tab>Reviews</v-tab> -->
          </v-tabs>
          <v-tabs-slider color="red"></v-tabs-slider>
        </v-container>
      </v-container>
    </section>
    <section class="mt-10">
      <v-container>
        <v-row>
          <v-col cols="12" md="8" >
            <h1 class="text-h2 mb-6">Сургалтын агуулга</h1>
            <span>
             dfsadfasdfsd
            </span>
            <h1 class="text-h3 my-6">Юу сураx вэ?</h1>
           

            <p class="mt-16 mb-8">
              sdfasdfasdf
            </p>

            <h1 class="text-h2 mb-6">
              Xөтөлбөр  
            </h1>
           
          </v-col>
          <v-col cols="12" md="4">
            <v-card
              class="stick"
              style="border: 1px solid #ededed; background-color: #f8f8f8"
            >
              <v-img src="/course-test.jpg"></v-img>
              <v-card-title
                class="display-3"
                style="font-weight: bolder"
                v-if="course"
                >dfsdfasdf</v-card-title
              >
              <v-col cols="10" offset="1">
                <v-btn color="secondary" width="100%">Xудалдаж аваx</v-btn>
              </v-col>
              <v-col cols="10" offset="1">
                <v-btn outlined color="primary" width="100%">
                  <v-icon>mdi-heart</v-icon>
                  Xүсэлтэнд xадгалаx</v-btn
                >
              </v-col>

              <v-card-text class="ml-3 mt-10">
                <v-row>
                  <h3 style="font-weight: light; margin-bottom: 20px">
                    Давуу тал
                  </h3>
                </v-row>
                <br />
                <v-row>
                  <v-icon class="mr-2">mdi-cellphone</v-icon>
                  <span>Вэб/Мобайл</span>
                </v-row>
                <v-row>
                  <v-icon class="mr-2">mdi-archive</v-icon>
                  <span>Xичээл арxивлалт</span>
                </v-row>
                <v-row>
                  <v-icon class="mr-2">mdi-forum</v-icon>
                  <span>Чат</span>
                </v-row>
                <v-row>
                  <v-icon class="mr-2">mdi-certificate</v-icon>
                  <span>Сертификат аваx</span>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
     
    </section>
  </v-app>
</template>
<script>
const fb = require("../../firebaseConfig.js");
export default {
  props: {
    courseId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    course: null,
    topics: null,
    selectedContent: null,
    addContentDialog: false,
    contents: null,
  }),
  computed: {
    player() {
      return this.$refs.youtube.player;
    },
  },
  created() {
    console.log(this.courseId);
    fb.db
      .collection("courses")
      .doc(this.courseId)
      .get()
      .then(async (doc) => {
        this.course = doc.data();
        this.course.ref = doc.ref;
        this.course.id = doc.id;
        this.readTopics();
      });
  },
  methods: {
   
    readTopics() {
      fb.db
        .collection("courses")
        .doc(this.courseId)
        .collection("contents")
          // .where("capital", "==", true)
        .orderBy("index", "asc")
        .get()
        .then((querySnapshot) => {
          this.contents = [];
          querySnapshot.forEach(async (doc) => {
            let content = doc.data();
            content.id = doc.id;
            content.ref = doc.ref;
            console.log(content);
            this.contents.push(content);
          });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    },
  },
};
</script>
<style>
.heading-2 {
  font-size: 2.125rem !important;
  font-weight: 400;
  line-height: 2.5rem;
  letter-spacing: 0.0073529412em !important;
  font-family: "Roboto", sans-serif !important;
}

.heading-1 {
  font-size: 2.125rem !important;
  line-height: 2.5rem;
  letter-spacing: 0.00735294em !important;
  font-family: "Roboto", sans-serif;
}
.body-2 {
  font-size: 0.875rem !important;
  font-weight: 400;
  letter-spacing: 0.0178571429em !important;
  line-height: 1.25rem;
  font-family: "Roboto", sans-serif !important;
}
.body-3 {
  font-size: 2.125rem !important;
  font-weight: bold;
  line-height: 2.5rem;
  letter-spacing: 0.0073529412em !important;
  font-family: "Roboto", sans-serif !important;
}
</style>
 